var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "findPwPopup-wrapper" },
    [
      _c(
        "general-simple-popup",
        {
          attrs: { "has-header-close-button": true },
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("h2", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("아이디 중복체크"),
          ]),
          _c(
            "div",
            { attrs: { slot: "contents" }, slot: "contents" },
            [
              _c("p", { staticClass: "mb-40 description" }, [
                _vm._v("아이디로 사용할 이메일 주소를 입력하세요."),
              ]),
              _c("input-box", {
                staticClass: "mb-40",
                attrs: {
                  id: "email",
                  autocomplete: "off",
                  placeholder: "예시) factory@maxst.com",
                  maxlength: "30",
                },
                on: { input: _vm.onCheckValidity },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "blue-border-1",
                    disabled: !_vm.isValidEmail,
                  },
                  on: { click: _vm.onCheck },
                  slot: "button",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("message.email_chekck_button")) + " "
                  ),
                ]
              ),
              _vm.info.description
                ? _c(
                    "p",
                    {
                      staticClass: "info-text",
                      class: {
                        "error-text": !_vm.isValidEmail && _vm.startEmail,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.info.description) + " ")]
                  )
                : _vm._e(),
              _vm.popupData.checkEmail
                ? _c("div", { staticClass: "emailCheckStep2" }, [
                    _c("p", [
                      _c("strong", [_vm._v(_vm._s(_vm.popupData.checkEmail))]),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.popupData.checkDescription),
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.popupData.confirmButton && _vm.popupData.checkEmail
            ? _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "blue",
                    disabled: !_vm.isValidEmail,
                  },
                  on: { click: _vm.onConfirmInPopup },
                  slot: "button",
                },
                [_vm._v(" " + _vm._s(_vm.popupData.confirmButton) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("confirm-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "ConfirmPopup",
          contentsKey: _vm.popupContentsKey,
        },
        on: { close: _vm.togglePopup, confirm: _vm.onConfirmInPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }