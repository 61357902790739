<template>
  <li :class="{ 'input-and-button': info.key === 'loginId' }">
    <slot name="label" :class="{ 'label-required': info.isRequired }" />
    <p v-if="info.key === 'password' && isUsingPassword">설비회사로 사용중인 비밀번호로 동일하게 유지됩니다.</p>
    <input-box
      v-model="inputValue"
      v-else-if="info.editType === 'input'"
      :id="info.key"
      autocomplete="off"
      :type="info.type || 'text'"
      @input="onChangeValue"
      :default-message="info.defaultMessage"
      :error-message="!isValidPassword ? info.defaultMessage : ''"
      @click="onClickLoginIdPopup"
      :disabled="isDisabled || info.key === 'loginId'"
      :tabindex="tabindex"
      :maxlength="maxlength"
    />
    <radio-basic
      v-model="inputValue"
      v-else-if="isRegister && info.editType === 'radio'"
      v-for="(radio, index) in radioLabel"
      :key="index"
      :value="radio.value"
      color="blue"
      @input="onChangeValue"
      :checked="radio.checked"
    >
      {{ radio.label }}
    </radio-basic>
    <span class="overlap-button" v-if="info.key === 'loginId'">
      <button-basic size="s" color="gray-border-1" @click="onEmailCheck">중복확인</button-basic>
    </span>
  </li>
</template>
<script>
import InputBox from '@/component/input/InputBox.vue';

export default {
  name: 'UserRegisterItem',
  props: [
    'info',
    'inputSideButton',
    'inputKey',
    'infoValue',
    'radioLabel',
    'isRegister',
    'isUsingPassword',
    'isDisabled',
    'isValidPassword',
    'tabindex',
    'maxlength',
  ],
  data() {
    return {
      inputValue: '',
      showedPopupName: '',
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    infoValue(newVal) {
      if (newVal) {
        this.inputValue = newVal;
      }
    },
  },
  methods: {
    onClickLoginIdPopup() {
      if (this.info.key === 'loginId') {
        this.onEmailCheck();
      }
    },
    toggleIsEditing() {
      this.isEditing = !this.isEditing;
    },
    onChangeValue() {
      this.$emit('saveChangeValue', this.inputKey, this.inputValue);
    },
    onEmailCheck() {
      this.$emit('emailCheck');
    },
  },
  components: { InputBox },
};
</script>
<style scoped lang="scss">
@import 'UserRegisterItem';
</style>
