<template>
  <section class="main-section bg-white">
    <main-section-header title="담당자 등록" />
    <div class="container">
      <subtitle subtitle="담당자 정보" />
      <ul class="detail-list">
        <user-register-item
          :is-register="true"
          v-for="(key, index) in Object.keys(userInfo)"
          :info-value="userInfo[key].value"
          :key="index"
          :info="userInfo[key]"
          :inputKey="key"
          :radioLabel="radioLabel[currentCompany]"
          :isRegister="true"
          @saveChangeValue="onSaveInputValue"
          @emailCheck="onEmailCheck"
          :isUsingPassword="isUsingPassword"
          :isDisabled="isDisabled"
          :isValidPassword="isValidPassword"
          :tabindex="(index + 1).toString()"
          :maxlength="userInfo[key].maxlength"
        >
          <span slot="label" class="label" :class="{ 'label-required': userInfo[key].isRequired }">
            {{ userInfo[key].label }}
          </span>
        </user-register-item>
      </ul>
      <list-bottom-button-box>
        <button-basic slot="button" color="gray-border-1" size="m" :width="120" @click="onMoveUserList">
          취소
        </button-basic>
        <button-basic
          slot="button"
          color="primary"
          size="m"
          :width="120"
          :disabled="!isFilledAllRequiredField"
          @click="togglePopup('ConfirmPopup', 'USER_RESISTER')"
        >
          등록
        </button-basic>
      </list-bottom-button-box>
    </div>
    <email-check-popup
      v-if="showedPopupName === 'EmailCheckPopup'"
      @confirmedUsingEmail="onConfirmedUsingEmail"
      @confirmedUsingUser="onConfirmedUsingUser"
      @close="togglePopup"
      :info="emailCheckPopupData"
    />
    <confirm-popup
      :isShow="showedPopupName === 'ConfirmPopup'"
      :contentsKey="popupContentsKey"
      @close="onCloseConfirmPopup"
      @confirm="onConfirmInPopup"
      :isLoading="isLoadingSave"
    />
  </section>
</template>

<script>
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import Subtitle from '@/component/subtitle/Subtitle';
import ListBottomButtonBox from '@/component/list/ListBottomButtonBox';
import UserRegisterItem from './userRegisterItem/UserRegisterItem';
import { mapActions, mapState } from 'vuex';
import EmailCheckPopup from '@/component/popup/emailCheckPopup/EmailCheckPopup';
import UserRegisterData from './userRegisterData/UserRegisterData.json';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import validPassword from '@/asset/js/validPassword';
import RadioLabel from './userRegisterData/RadioLabel.json';

export default {
  name: 'UserRegister',
  props: [],
  data() {
    return {
      radioLabel: RadioLabel,
      userInfo: {},
      emailCheckPopupData: UserRegisterData.emailCheckPopup,
      registerData: {
        loginId: '',
        password: '',
        userName: '',
        phone: '',
        role: '',
      },
      isLoadingSave: false,
      showedPopupName: '',
      popupContentsKey: '',
      passwordDescription: null,
      isUsingPassword: false,
      isDisabled: false,
      isValidPassword: true,
    };
  },
  computed: {
    ...mapState('users', ['currentCompany']),
    isFilledAllRequiredField() {
      let isFilledAll = true;
      Object.values(this.userInfo).forEach((info) => {
        if ((info.isRequired && !this.registerData[info.key]) || !this.isValidPassword) {
          isFilledAll = false;
        } else if (this.isUsingPassword) {
          isFilledAll = true;
        }
      });
      return isFilledAll;
    },
  },
  created() {
    this.initRenderData();
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('factoryUsers', ['POST_FACTORY_USER', 'POST_FACTORY_USER_ROLE']),
    ...mapActions('manufacturerUsers', ['POST_MANUFACTURER_USER', 'POST_MANUFACTURER_USER_ROLE']),
    initPasswordError() {
      this.isValidPassword = true;
    },
    onCloseConfirmPopup() {
      switch (this.popupContentsKey) {
        case 'USER_RESISTER_SUCCESS':
          this.togglePopup();
          this.onMoveUserList();
          break;
        default:
          this.togglePopup();
          break;
      }
    },
    onConfirmedUsingEmail(email) {
      this.togglePopup();
      this.userInfo['loginId'].value = email;
      this.registerData.loginId = email;
    },
    onConfirmedUsingUser(userInfo) {
      this.togglePopup();
      this.userInfo['userName'].value = userInfo.userName;
      this.userInfo['loginId'].value = userInfo.loginId;
      this.userInfo['phone'].value = userInfo.phone;
      this.registerData.userName = userInfo.userName;
      this.registerData.loginId = userInfo.loginId;
      this.registerData.phone = userInfo.phone;
      this.isUsingPassword = true;
      this.isDisabled = true;
    },
    onMoveUserList() {
      this.$router.push(`/users`).catch((err) => err);
    },
    onSaveInputValue(inputKey, changedValue) {
      if (inputKey === 'password') {
        this.initPasswordError();
        if (changedValue === '') {
          this.initPasswordError();
        } else if (!validPassword(changedValue)) {
          this.isValidPassword = false;
        }
      }
      this.registerData[inputKey] = changedValue;
    },
    initRenderData() {
      this.userInfo = UserRegisterData.userInfo;
      this.isUsingPassword = false;
      this.userInfo['userName'].value = '';
      this.userInfo['loginId'].value = '';
      this.userInfo['phone'].value = '';
      this.registerData.userName = '';
      this.registerData.loginId = '';
      this.registerData.phone = '';
      this.isDisabled = false;
    },
    async onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'USER_RESISTER':
          try {
            this.isLoadingSave = true;
            this.isUsingPassword ? await this.postUserGiveRole() : await this.onSaveUser();
            this.togglePopup('ConfirmPopup', 'USER_RESISTER_SUCCESS');
          } finally {
            this.isLoadingSave = false;
          }
          break;
      }
    },
    async postUserGiveRole() {
      const userRoleInfo = {
        loginId: this.registerData.loginId,
        role: this.registerData.role,
      };
      switch (this.currentCompany) {
        case 'M':
          await this.POST_MANUFACTURER_USER_ROLE(userRoleInfo);
          break;
        case 'F':
          await this.POST_FACTORY_USER_ROLE(userRoleInfo);
          break;
      }
    },
    async onSaveUser() {
      switch (this.currentCompany) {
        case 'M':
          await this.POST_MANUFACTURER_USER(this.registerData);
          break;
        case 'F':
          await this.POST_FACTORY_USER(this.registerData);
          break;
      }
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    onEmailCheck() {
      this.togglePopup('EmailCheckPopup', 'EMAIL_CHECK');
    },
  },
  components: {
    MainSectionHeader,
    Subtitle,
    ListBottomButtonBox,
    UserRegisterItem,
    EmailCheckPopup,
    ConfirmPopup,
  },
};
</script>

<style scoped lang="scss">
@import 'UserRegister';
</style>
