var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section bg-white" },
    [
      _c("main-section-header", { attrs: { title: "담당자 등록" } }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("subtitle", { attrs: { subtitle: "담당자 정보" } }),
          _c(
            "ul",
            { staticClass: "detail-list" },
            _vm._l(Object.keys(_vm.userInfo), function (key, index) {
              return _c(
                "user-register-item",
                {
                  key: index,
                  attrs: {
                    "is-register": true,
                    "info-value": _vm.userInfo[key].value,
                    info: _vm.userInfo[key],
                    inputKey: key,
                    radioLabel: _vm.radioLabel[_vm.currentCompany],
                    isRegister: true,
                    isUsingPassword: _vm.isUsingPassword,
                    isDisabled: _vm.isDisabled,
                    isValidPassword: _vm.isValidPassword,
                    tabindex: (index + 1).toString(),
                    maxlength: _vm.userInfo[key].maxlength,
                  },
                  on: {
                    saveChangeValue: _vm.onSaveInputValue,
                    emailCheck: _vm.onEmailCheck,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "label",
                      class: { "label-required": _vm.userInfo[key].isRequired },
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [_vm._v(" " + _vm._s(_vm.userInfo[key].label) + " ")]
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "list-bottom-button-box",
            [
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "gray-border-1",
                    size: "m",
                    width: 120,
                  },
                  on: { click: _vm.onMoveUserList },
                  slot: "button",
                },
                [_vm._v(" 취소 ")]
              ),
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "primary",
                    size: "m",
                    width: 120,
                    disabled: !_vm.isFilledAllRequiredField,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.togglePopup("ConfirmPopup", "USER_RESISTER")
                    },
                  },
                  slot: "button",
                },
                [_vm._v(" 등록 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showedPopupName === "EmailCheckPopup"
        ? _c("email-check-popup", {
            attrs: { info: _vm.emailCheckPopupData },
            on: {
              confirmedUsingEmail: _vm.onConfirmedUsingEmail,
              confirmedUsingUser: _vm.onConfirmedUsingUser,
              close: _vm.togglePopup,
            },
          })
        : _vm._e(),
      _c("confirm-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "ConfirmPopup",
          contentsKey: _vm.popupContentsKey,
          isLoading: _vm.isLoadingSave,
        },
        on: { close: _vm.onCloseConfirmPopup, confirm: _vm.onConfirmInPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }