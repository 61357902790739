var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { class: { "input-and-button": _vm.info.key === "loginId" } },
    [
      _vm._t("label"),
      _vm.info.key === "password" && _vm.isUsingPassword
        ? _c("p", [
            _vm._v("설비회사로 사용중인 비밀번호로 동일하게 유지됩니다."),
          ])
        : _vm.info.editType === "input"
        ? _c("input-box", {
            attrs: {
              id: _vm.info.key,
              autocomplete: "off",
              type: _vm.info.type || "text",
              "default-message": _vm.info.defaultMessage,
              "error-message": !_vm.isValidPassword
                ? _vm.info.defaultMessage
                : "",
              disabled: _vm.isDisabled || _vm.info.key === "loginId",
              tabindex: _vm.tabindex,
              maxlength: _vm.maxlength,
            },
            on: { input: _vm.onChangeValue, click: _vm.onClickLoginIdPopup },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _vm.isRegister && _vm.info.editType === "radio"
        ? _vm._l(_vm.radioLabel, function (radio, index) {
            return _c(
              "radio-basic",
              {
                key: index,
                attrs: {
                  value: radio.value,
                  color: "blue",
                  checked: radio.checked,
                },
                on: { input: _vm.onChangeValue },
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              },
              [_vm._v(" " + _vm._s(radio.label) + " ")]
            )
          })
        : _vm._e(),
      _vm.info.key === "loginId"
        ? _c(
            "span",
            { staticClass: "overlap-button" },
            [
              _c(
                "button-basic",
                {
                  attrs: { size: "s", color: "gray-border-1" },
                  on: { click: _vm.onEmailCheck },
                },
                [_vm._v("중복확인")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }