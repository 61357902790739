<template>
  <div class="findPwPopup-wrapper">
    <general-simple-popup :has-header-close-button="true" @close="$emit('close')">
      <h2 slot="title">아이디 중복체크</h2>
      <div slot="contents">
        <p class="mb-40 description">아이디로 사용할 이메일 주소를 입력하세요.</p>
        <input-box
          id="email"
          v-model="email"
          autocomplete="off"
          placeholder="예시) factory@maxst.com"
          class="mb-40"
          maxlength="30"
          @input="onCheckValidity"
        />
        <button-basic slot="button" color="blue-border-1" @click="onCheck" :disabled="!isValidEmail">
          {{ $t('message.email_chekck_button') }}
        </button-basic>
        <p class="info-text" :class="{ 'error-text': !isValidEmail && startEmail }" v-if="info.description">
          {{ info.description }}
        </p>
        <div class="emailCheckStep2" v-if="popupData.checkEmail">
          <p>
            <strong>{{ popupData.checkEmail }}</strong>
            <span v-html="popupData.checkDescription" />
          </p>
        </div>
      </div>
      <button-basic
        slot="button"
        color="blue"
        :disabled="!isValidEmail"
        v-if="popupData.confirmButton && popupData.checkEmail"
        @click="onConfirmInPopup"
      >
        {{ popupData.confirmButton }}
      </button-basic>
    </general-simple-popup>
    <confirm-popup
      :isShow="showedPopupName === 'ConfirmPopup'"
      :contentsKey="popupContentsKey"
      @close="togglePopup"
      @confirm="onConfirmInPopup"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GeneralSimplePopup from '@/component/popup/generalSimplePopup/GeneralSimplePopup';
import InputBox from '@/component/input/InputBox';
import validEmail from '@/asset/js/validEmail';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';

export default {
  name: 'emailCheckPopup',
  props: ['info'],
  data() {
    return {
      email: '',
      userName: '',
      phone: '',
      isValidEmail: false,
      showedPopupName: '',
      popupContentsKey: '',
      startEmail: false,
      popupData: {
        checkEmail: '',
        checkDescription: '',
        confirmButton: null,
      },
      checkCase: {},
    };
  },
  computed: {
    ...mapState('users', ['currentCompany']),
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('users', ['GET_ID_DUPLICATE_CHECK']),
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    onCheckValidity(value) {
      this.isValidEmail = !!validEmail(value);
      this.startEmail = true;
      this.email = value;
    },
    async onCheck() {
      this.popupData.checkEmail = this.email;
      const check = await this.GET_ID_DUPLICATE_CHECK([this.email, this.currentCompany]);
      this.checkCase = check;
      switch (check.result) {
        case 0:
          this.popupData.checkDescription = '은 사용가능한 아이디입니다.';
          this.popupData.confirmButton = '아이디 사용하기';
          break;
        case 1:
          switch (this.currentCompany) {
            case 'M':
              this.popupData.checkDescription =
                '은 도입회사로 사용중인 계정입니다. <br/>설비회사도 사용 가능하게끔 설정하시겠습니까?';
              this.popupData.confirmButton = '설비회사 추가 사용';
              break;
            case 'F':
              this.popupData.checkDescription =
                '은 설비회사로 사용중인 계정입니다. <br/>도입회사도 사용 가능하도록 설정하시겠습니까?';
              this.popupData.confirmButton = '도입회사 추가 사용';
              break;
          }
          break;
        case 2:
          this.popupData.checkDescription = '은 이미 사용중인 아이디 입니다.';
          this.popupData.confirmButton = null;
          break;
      }
    },
    onConfirmInPopup() {
      switch (this.checkCase.result) {
        case 0:
          this.$emit('confirmedUsingEmail', this.email);
          break;
        case 1:
          this.$emit('confirmedUsingUser', this.checkCase);
          this.$emit('isDisabled', true);
      }
    },
  },
  components: { InputBox, GeneralSimplePopup, ConfirmPopup },
};
</script>

<style scoped lang="scss">
@import 'EmailCheckPopup';
</style>
